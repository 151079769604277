<template>
  <div class="wrap">
    <!-- <van-nav-bar title="排班查看" left-arrow @click-left="onClickLeft" /> -->
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 24px"
        @click="onClickLeft"
        v-show="!backIcon"
      />
      <span style="font-size: 20px; color: #323233; font-weight: bold">
        排班查看
      </span>
    </div>
    <van-tabs v-model="active" title-active-color="#1578F9" @click="tabOnClick">
      <van-tab title="我的排班"></van-tab>
      <!-- <van-tab title="手术排班"></van-tab> -->
      <van-tab title="全部排班"></van-tab>
    </van-tabs>
    <div class="det">
      <div class="det_box" @click="showPicker = true">
        {{ deptName }}
      </div>
    </div>
    <div class="content_1" v-if="active == 0">
      <div class="content1_info" v-if="dataList.length != 0">
        <div class="title" @click="show = true">{{ date }}</div>
        <ul class="day">
          <li>
            <div class="day_box" v-for="(item, i) in dataList" :key="i">
              <div class="day_num">
                {{ item.date ? item.date.substr(-2, 2) : "" }}
              </div>
              <div
                class="day_tag"
                :style="{
                  background:
                    item.scheduleStatus == 0
                      ? '#BDC4CE'
                      : item.scheduleStatus == 1
                      ? '#1AB29B '
                      : item.scheduleStatus == 2
                      ? '#1578F9'
                      : item.scheduleStatus == 3
                      ? '#F9BF75 '
                      : '#C0C4CC',
                }"
              >
                {{ item.scheduleStr }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- <van-empty description="暂无" v-if="active == 1"/> -->
    <div v-if="active == 0">
      <div class="emptyBox" v-if="dataList.length == 0">
        <img src="../../assets/images/empty.png" />
        <div>暂无数据</div>
      </div>
    </div>
    <div class="content" v-if="active == 1">
      <div class="content_info" v-if="dataAllList.length != 0">
        <div class="title">{{ dateDatail }}</div>
        <div class="date_box">
          <div class="img_box">
            <img
              src="../../assets/images/date_time.png"
              style="width: 42px; height: 40px"
            />
          </div>
          <div class="date_info">
            <!-- <div class="date1">
              <div>MO</div>
              <div>TU</div>
              <div>WE</div>
              <div>TH</div>
              <div>FR</div>
              <div>SA</div>
              <div>SU</div>
            </div>
            <div class="date2">
              <div>01</div>
              <div>02</div>
              <div>03</div>
              <div>04</div>
              <div>05</div>
              <div>06</div>
              <div>07</div>
            </div> -->
            <div class="date3">
              <div class="date3_box" v-for="(item, i) in dateList" :key="i">
                <div style="font-size: 15px; color: #bdc4ce">
                  {{ item.weekStr }}
                </div>
                <div style="font-size: 13px; color: #06121e; margin-top: 8px">
                  {{ item.date ? item.date.substr(-2, 2) : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user_box" v-for="(item, i) in dataAllList" :key="i">
          <div class="name_title">{{ item.doctorName }}</div>
          <div
            class="tag_box"
            v-for="(items, i) in item.dateScheduleList"
            :key="i"
            :style="{
              background:
                items.status == 0
                  ? '#BDC4CE'
                  : items.status == 1
                  ? '#1AB29B '
                  : items.status == 2
                  ? '#1578F9'
                  : items.status == 3
                  ? '#F9BF75 '
                  : '#C0C4CC',
            }"
          >
            {{ items.detail }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="active == 1">
      <div class="emptyBox" v-if="dataAllList.length == 0">
        <img src="../../assets/images/empty.png" />
        <div>暂无数据</div>
      </div>
    </div>
    <van-calendar
      v-model="show"
      type="range"
      :default-date="defaultDate"
      @confirm="onConfirm"
      :max-range="7"
    />
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        show-toolbar
        :columns="dotorDeptList"
        @confirm="pickerOnConfirm"
        @cancel="onCancel"
        @change="onChange"
        value-key="deptName"
    /></van-popup>
  </div>
</template>
<script>
import {
  getMySchedule,
  getDotorDept,
  getDeptSchedule,
} from "@/api/workingSchedule";
import dateUtil from "@/api/dateUtil.js";

export default {
  data() {
    return {
      active: 0,
      date: "",
      deptId: "",
      startDateStr: "",
      endDateStr: "",
      deptName: "",
      show: false,
      showPicker: false,
      dataList: [],
      dotorDeptList: [],
      dataAllList: [],
      defaultDate: [],
      dateList: [],
      dateDatail: "",
      backIcon: localStorage.getItem("routerHistory"),
    };
  },
  created() {
    this.startDateStr = dateUtil.formatDate3(new Date());
    this.endDateStr = dateUtil.formatDate3(new Date().getTime() + 86400000 * 6);
    this.date = dateUtil.formatDate5(new Date());
    // this.defaultDate = [new Date(), new Date().setTime(new Date().getTime() + 86400000 * 5)];
    const endDate = new Date();
    endDate.setTime(endDate.getTime() + 3600 * 1000 * 24 * 6);
    console.log(endDate, "endDate");
    this.defaultDate = [new Date(), endDate];
    this.getDotorDeptLits();
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: "/menu" });
    },
    tabOnClick(name, title) {
      if (name == 1) {
        this.getAlldata();
      } else {
        this.getdata();
      }
    },
    getdata() {
      getMySchedule(
        dateUtil.formatDate3(this.startDateStr),
        dateUtil.formatDate3(this.endDateStr),
        this.deptId
      ).then((res) => {
        if (res.data.code == "200") {
          this.dataList = res.data.data;
          console.log(this.dataList,'this.dataList')
        }
      });
    },
    // 全部排班
    getAlldata() {
      getDeptSchedule(this.deptId).then((res) => {
        if (res.data.code == "200") {
          this.dataAllList = res.data.data.scheduleList;
          this.dateList = res.data.data.dateList;
          this.dateDatail = dateUtil.formatDate5(
            res.data.data.dateList[0].date
          );
        }
      });
    },
    // 我的排班
    getDotorDeptLits() {
      getDotorDept().then((res) => {
        if (res.data.code == "200") {
          this.dotorDeptList = res.data.data;
          this.deptId = res.data.data[0].id;
          this.deptName = res.data.data[0].deptName;
          this.getdata();
        }
      });
    },
    pickerOnConfirm(value, index) {
      this.deptId = value.id;
      if (this.active == 0) {
        this.getdata();
      } else {
        this.getAlldata();
      }
      this.showPicker = false;
    },
    onChange(picker, value, index) {},
    onCancel() {
      this.showPicker = false;
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.startDateStr = start;
      this.endDateStr = end;
      this.show = false;
      this.date = dateUtil.formatDate5(end);
      this.getdata();
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 20px;

  .nav_bar {
    text-align: center;
    color: #323233;
    margin-bottom: 20px;
  }
  .det {
    padding: 16px 16px 0 16px;
    box-sizing: border-box;

    .det_box {
      padding: 10px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #ffffff;
      font-size: 16px;
    }
  }

  .content_1 {
    padding: 16px;
    box-sizing: border-box;

    .content1_info {
      padding: 16px 10px 4px 10px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #ffffff;

      .title {
        color: #06121e;
        font-size: 16px;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 12px;
        letter-spacing: 1px;
      }

      .day {
        margin-top: 12px;
      }

      .day li {
        display: flex;
        flex-flow: wrap;
        align-items: center;

        .day_box {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 15px;
          margin-bottom: 12px;

          .day_num {
            margin-bottom: 8px;
            font-size: 16px;
            color: #06121e;
          }

          .day_tag {
            width: 32px;
            height: 17px;
            line-height: 18px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            background-color: #1578f9;
            border-radius: 2px 2px 2px 2px;
          }
        }

        .day_box:nth-child(7n) {
          margin-right: 0px;
        }
      }
    }
  }

  .content {
    padding: 16px;
    box-sizing: border-box;

    .content_info {
      padding: 16px 10px;
      box-sizing: border-box;
      border-radius: 10px;
      // height: 312px;
      background: #ffffff;

      .title {
        color: #06121e;
        font-size: 16px;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 12px;
        letter-spacing: 1px;
      }

      .date_box {
        padding: 12px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #f3f3f3;

        // display: flex;
        // align-items: center;
        .img_box {
          float: left;
          border-right: 1px solid #f3f3f3;
          padding-right: 6px;
          box-sizing: border-box;
        }

        .date_info {
          // border-left: 1px solid #F3F3F3;
          padding-left: 11px;
          padding-right: 8px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .date1 {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            color: #bdc4ce;
          }

          .date2 {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            margin-top: 8px;
            color: #06121e;
          }

          .date3 {
            display: flex;
            justify-content: space-between;

            .date3_box {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }

      .user_box {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f3f3f3;
        padding: 13px 0;
        box-sizing: border-box;

        .name_title {
          border-right: 1px solid #f3f3f3;
          padding-right: 6px;
          box-sizing: border-box;
          font-size: 12px;
          min-width: 49px;
          text-align: center;
        }

        .tag_box {
          width: 32px;
          height: 17px;
          line-height: 18px;
          text-align: center;
          font-size: 12px;
          color: #ffffff;
          border-radius: 2px 2px 2px 2px;
          background-color: #1578f9;
        }
      }
    }
  }
}

/deep/.van-tabs__line {
  display: none;
}
.emptyBox {
  width: 100%;
  height: 100%;
  text-align: center;
  margin: 20% auto;
  img {
    width: 50%;
    height: 50%;
    margin: 0 auto;
  }
}
</style>
