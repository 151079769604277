import request from '@/router/axios';

// 我的排班
export const getMySchedule = (startDateStr, endDateStr, deptId) => request({
  url: '/api/blade-handheld-hospital/schedule/qwx/getMySchedule',
  method: 'get',
  params: {
    startDateStr,
    endDateStr,
    deptId,
  },

});
export const getDotorDept = () => request({
  url: '/api/blade-handheld-hospital/schedule/qwx/getDotorDept',
  method: 'get',
});

// 全部排班
export const getDeptSchedule = (deptId) => request({
  url: '/api/blade-handheld-hospital/schedule/qwx/getDeptSchedule',
  method: 'get',
  params: {
    deptId,
  },
});
